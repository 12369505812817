.text-danger {
	color: $brand-danger
}
.text-warning {
	color: $brand-warning
}

.text-muted {
	color: #00b1b1;
}

.text-larger {
	font-size: 1.15em;
}

@media(min-width: $tablet + 1) {

	.text-left-tablet {
		text-align: left;
	}

}



// $brand-primary: #3097D1;
// $brand-info: #8eb4cb;
// $brand-success: #2ab27b;
// $brand-warning: #cbb956;
// $brand-danger: #bf5329;