.logs-container {
  .stack {
    font-size: 0.85em;
  }
  .date {
    min-width: 75px;
  }
  .text {
    word-break: break-all;
  }
  a.llv-active {
    z-index: 2;
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $white;

    .badge {
      background: $white;
      color: $text-color;
      margin-top: .2em;
    }
  }
}