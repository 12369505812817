::-ms-reveal,
::-ms-clear {
  display: none !important;
}

.hideShowPassword-wrapper {
  width: 100%;

  &.pass-strength-visible {

    .hideShowPassword-toggle {
      top: 13px !important;
    }

  }

}

.hideShowPassword-toggle {
  background-color: transparent;
  background-image: url('/images/wink.png'); /* fallback */
  background-image: url('/images/wink.svg'), none;
  background-position: 0 center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  margin: 0;
  max-height: 100%;
  padding: 0;
  overflow: 'hidden';
  text-indent: -999em;
  width: 46px;
  margin-top: -18px !important;
  top: 18px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;

  &:hover,
  &:focus {
    border-color: #0088cc;
    outline: transparent;
  }

}

.hideShowPassword-toggle-hide {
  background-position: -44px center;
}


